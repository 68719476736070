<!-- 交易流转资源资产类型 -->
<template>
  <LandCard title="交易流转资源资产类型" :isLeftMore="false">
    <div class="echartStyle">
      <chart :options="options"></chart>
    </div>
  </LandCard>
</template>

<script>
import LandCard from "@/components/Card/landTrustCard.vue";
import chart from "@/components/echart/echart";
import { staticcountarea } from "@/api/resourceAssetService.js";
export default {
  components: {
    LandCard,
    chart,
  },
  data() {
    return {
      options: null,
      areaData: [
        {
          show: true,
          name: "普光镇",
          value: 115.27,
          percent: "5.8%",
        },
        {
          show: true,
          name: "天生镇",
          value: 1496.47,
          percent: "75.3%",
        },
        { show: true, name: "柏树镇", value: 83.47, percent: "4.2%" },
        { show: true, name: "芭蕉镇", value: 41.73, percent: "2.1%" },
        { show: true, name: "茶河镇", value: 250.41, percent: "12.6%" },
      ],
    };
  },

  computed: {
    total() {
      let res = 0;
      this.areaData.forEach((item) => {
        res = res + item.value;
      });
      return { res };
    },
  },
  mounted() {
    this.getAreaData();
    // this.init(this.areaData);
  },
  methods: {
    async getAreaData() {
      let res = await staticcountarea();
      if (res.status) {
        this.areaData = [];
        res.data.forEach((item) => {
          // console.log(item,"查看字段")
          if (item) {
            this.areaData.push({
              show: true,
              name: item.cqlx ? item.cqlx : '暂无',
              value: item.value,
              percent: (item.percentage * 100).toFixed(2) + '%',
            });
          }
        });
        this.init(this.areaData);
        // this.areaData = res.data;
        // this.areaData.forEach((item) => {
        //   item.show = true;
        //   item.name = item.xzq;
        // });
      }
    },
    init(obj) {
      const dataList = obj;
      this.options = {
        xAxis: {
          show: false,
        },
        yAxis: {
          show: false,
        },
        tooltip: {
          trigger: "item",
          showContent: true,
          backgroundColor: "rgba(8,36,68,.7)",
          color: "#fff",
          textStyle: {
            color: "#fff",
          },
          //valueFormatter: (value) => value + " " + "亩",
          formatter: function (params) {
            var html =
              params.marker +
              "  " +
              "资源资产类型" +
              "：" +
              params.name +
              "<br>";
            html +=
              params.marker +
              "  " +
              "交易流转面积" +
              "：" +
              params.value +
              "亩" +
              "<br>" +
              params.marker +
              "  " +
              "所占比例" +
              "：" +
              params.percent +
              "%";
            return html;
          },
        },
        title: {
          show: true,
          top: "43%",
          left: "34%",
          textAlign: "center",
          text: "交易流转总面积" + "\n" + this.total.res.toFixed(2) + "亩",
          textStyle: {
            color: "#333333",
            fontSize: 16,
            fontWeight: "bold",
            lineHeight: 30,
          },
        },
        legend: {
          selectedMode: true, // 图例上的点击事件
          orient: "vertical",
          left: "70%",
          bottom: "10%",
          align: "left",
          itemGap: 12,
          itemWidth: 12, // 设置宽度
          itemHeight: 12, // 设置高度
          //symbolKeepAspect: false,
          textStyle: {
            color: "#000",
            rich: {
              name: {
                verticalAlign: "right",
                align: "left",
                fontSize: 16,
                color: "#71809A",
              },
              value: {
                align: "left",
                fontSize: 18,
                color: "#71809A",
              },
            },
          },
          data: dataList.map((item) => {
            if (item.show) {
              return item.name;
            }
          }),
          formatter: function (name) {
            if (dataList && dataList.length) {
              for (var i = 0; i < dataList.length; i++) {
                if (name === dataList[i].name) {
                  return "{name| " + name + "}  ";
                }
              }
            }
          },
        },
        series: [
          {
            name: "交易流转面积",
            type: "pie",
            radius: [78, 102],
            center: ["35%", "50%"],
            avoidLabelOverlap: true,
            startAngle: 200,
            label: {
              normal: {
                show: true,
                formatter: (params) => {
                  //return params.name+" "+params.percent+"%";
                  return params.name;
                },
                //lineHeight: 24,
                textStyle: {
                  //fontSize: 14,
                  fontSize: 16,
                  color: "inherit",
                },
              },
            },
            data: dataList,
          },
        ],
      };

      console.log( this.options );
    },
  },
};
</script>

<style lang="less" scoped>
.echartStyle {
  height: calc(100% - 50px);
}
</style>
