import { render, staticRenderFns } from "./RQuickLink.vue?vue&type=template&id=1ee95fd5&scoped=true&"
import script from "./RQuickLink.vue?vue&type=script&lang=js&"
export * from "./RQuickLink.vue?vue&type=script&lang=js&"
import style0 from "./RQuickLink.vue?vue&type=style&index=0&id=1ee95fd5&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ee95fd5",
  null
  
)

export default component.exports