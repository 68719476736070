<template>
  <el-dialog
    title="修改报价"
    :visible.sync="dialogVisible"
    :modal-append-to-body="false"
    :lock-scroll="false"
    width="50%"
    center
    @close="close"
  >
    <div class="form">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="当前最高出价：">
          <span>{{ data.dqbj ? data.dqbj + "元" : "无" }}</span>
        </el-form-item>
        <el-form-item label="挂牌总价：">
          <span>{{ data.gpjg }}元</span>
          <span class="BigWrite"> ({{ TotalLisTingPrice }}) </span>
        </el-form-item>
        <el-form-item label="最小加价幅度：">
          <span>{{ data.jjfd }} 元</span>
        </el-form-item>
        <el-form-item label="竞价截止时间：">
          <span>{{ data.jjjzsj }}</span>
        </el-form-item>
        <el-form-item label="加价：" prop="amount">
          <el-col :span="20">
            <el-input-number
              v-model.number="form.amount"
              :min="min"
              :precision="2"
              :step="step"
            ></el-input-number>
            元
          </el-col>
        </el-form-item>
        <el-form-item label="当前出价：">
          <span
            >{{
              data.dqbj ? data.dqbj + form.amount : data.gpjg + form.amount
            }}
            元</span
          >
          <span class="BigWrite">({{ CurrentBid }})</span>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" style="width: 116px" @click="submit"
        >确 定</el-button
      >
      <el-button type="primary" style="width: 116px" @click="close"
        >取 消</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getCurrentOffer } from "../../../api/projectManage";
import { digitUppercase } from "@/util/numConvert";
export default {
  data() {
    return {
      form: { amount: 0 },
      step: 0,
      min: 0,
      rules: {
        amount: [
          { required: true, message: "请输入加价数值", trigger: "blur" },
        ],
      },
      currentOffer: 0,
      dialogInterval: null,
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    TotalLisTingPrice() {
      return digitUppercase(this.data.gpjg);
    },
    CurrentBid() {
      return digitUppercase(
        this.data.dqbj
          ? this.data.dqbj + this.form.amount
          : this.data.gpjg + this.form.amount
      );
    },
  },
  created() {
    this.min = parseFloat(this.data.jjfd).toFixed(2);
    this.form.amount = this.min;
    if (!this.data.dqbj) {
      this.min = 0;
    }
    this.step = parseFloat(this.data.jjfd).toFixed(2);
    this.getCurrentOffer();
  },

  methods: {
    async getCurrentOffer() {
      // 获取当前报价
      let that = this;
      let params = {
        xmbh: that.data.xmbh,
      };
      // console.log(params,'弹窗的数据是否传过来了')
      let res = await getCurrentOffer(params);
      let { status, data } = res;
      if (status) {
        that.currentOffer = data;
      }
    },
    submit() {
      this.$confirm(
        "是否确认在当前最高价上加价 " + this.form.amount + " 元？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$emit("submit", this.form.amount, this.data.bjcs);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消加价",
          });
        });
    },
    close() {
      this.$refs.form.resetFields();
      this.$emit("close", false);
    },
  },
};
</script>
<style lang="less" scoped>

  /deep/.el-form-item{
    .el-form-item__content{
      .BigWrite{
        padding-left: 20px;
      }
    }
  }
</style>
