<!-- 托管组织排名 -->
<template>
  <LandCard title="交易流转区域面积排名" :isLeftMore="false">
    <template>
      <!-- <el-select
        class="selsectbox"
        v-model="yearValue"
        placeholder="请选择"
        size="small"
        @change="handleValueChange"
      >
        <el-option
          v-for="item in yearOptions"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
      <RankCard class="rankCardStyle" :list="data" :barColor="itemColor" />
    </template>
  </LandCard>
</template>

<script>
import LandCard from "@/components/Card/landTrustCard.vue";
import RankCard from "@/components/echart/CategoryRankChart.vue";
import { getFlowAreaSort } from "@/api/resourceAssetService.js";
import { mapGetters } from "vuex";
export default {
  components: {
    LandCard,
    RankCard
  },
  data() {
    return {
      yearValue: 2022,
      itemColor: '#EDBF51',

      yearOptions: [
      ],
      data: []
    };
  },
  mounted () {
    this.init();
  },
  watch:{
    topSelectAreacode: function () {
      this.getQYMJPM();
    },
  },
  computed: {
    ...mapGetters(["topSelectAreacode"]),
  },
  methods: {
    selectYear(n, m) {
      if (m === "" || m === undefined) m = 0;
      var myDate = new Date();
      var startYear = myDate.getFullYear() - n; //起始年份
      var endYear = myDate.getFullYear() + m; //结束年份
      var arr = new Array();
      for (var i = startYear; i <= endYear; i++) {
        var obj = { value: i, name: i + "年" };
        arr.push(obj);
      }
      return arr;
    },
    init() {
      this.yearOptions = this.selectYear(3);
      this.yearValue = this.yearOptions[3].value;
      this.getQYMJPM();
    },
    async getQYMJPM(){
      let params={
        // year:this.yearValue
        areaCode:this.topSelectAreacode ? this.topSelectAreacode : "",
      }
      let res=await getFlowAreaSort(params);
      if(res.status){
        this.data=res.data.slice(0, 6);;
        this.data.forEach(item => {
          item.name=item.xzqName;
          item.value=Number(item.area);
          // item.value=1000000000000;
        });
      }
    },
    handleValueChange(val){
      this.getQYMJPM();
    }
  }
};
</script>

<style lang="less" scoped>
.rankCardStyle{
    width: 100%;
    height: calc(100% - 100px);
}
.selsectbox {
  position: relative;
  top: -10%;
  left: 75%;

  width: 130px;

  /deep/ .el-input__inner {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
  }
}
</style>
