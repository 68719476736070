<template>
  <card title="快速入口">
    <ul class="quick-link">
      <li
        :class="item.code"
        v-for="item of linkList"
        :key="item.code"
        @click="menuHandle(item)"
      >
        {{ item.name }}
      </li>
    </ul>

    <div class="new-area">
      <div class="banner">
        <el-carousel height="350px" :interval="4000">
          <el-carousel-item>
            <img src="../../../assets/banner-3.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img src="../../../assets/banner-1.png" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="new-list">
        <ul class="news-tab">
          <li
            v-for="item of newTab"
            :key="item.code"
            :class="item.code == currentCode ? 'active' : ''"
            @click="tabHandle(item)"
          >
            {{ item.name }}
          </li>
        </ul>
        <div class="new-des">
          <ul>
            <li v-for="item of news" :key="item.code" @click="showDetail(item)">
              <div class="new-date">
                <span>{{
                  item.date && item ? item.date.substr(8, 2) : ""
                }}</span>
                <p>
                  {{ item.date && item ? item.date.substr(0, 4) : "" }}.{{
                    item.date && item ? item.date.substr(5, 2) : ""
                  }}
                </p>
              </div>
              <div class="new-title">
                <span>{{ item.title }}</span>
                <p class="des">
                  项目编号：{{ item.xmbh }}
                  <span v-if="item.currentIdentity == 2"
                    >信息来源：{{
                      item.from.substr(0, 1) +
                      (item.sex == "2" ? "女士" : "先生")
                    }}</span
                  >
                  <span v-else>{{ item.from }}</span>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { mapGetters } from "vuex";
import Card from "@/components/Card/index.vue";
import {
  getGpggPage,
  getCjggPage,
  getBiddingXmxxByPg,
} from "@/api/homeMessage";

const linkList = [
  {
    code: "one",
    name: "交易项目",
    title: "exchange",
  },
  {
    code: "two",
    name: "公告公示",
    title: "announcement",
  },
  {
    code: "three",
    name: "技术服务",
    title: "service",
  },
  {
    code: "four",
    name: "下载专区",
    title: "download",
  },
];
const newTab = [
  {
    code: 1,
    name: "资源资产供应信息",
  },
  {
    code: 2,
    name: "资源资产公告公示",
  },
];

const news = [];
export default {
  components: {
    Card,
  },

  data() {
    return {
      linkList,
      newTab,
      news,
      currentCode: 1,
      params: {
        current: 1,
        size: 4,
      },
    };
  },
  computed: mapGetters(["topSelectAreacode"]),
  mounted() {
    this.init();
  },
  watch: {
    topSelectAreacode(val) {
      this.tabHandle({ code: this.currentCode });
    },
  },
  methods: {
    menuHandle(item) {
      if (item.code == "one") {
        this.$router.push({ name: item.title, query: { index: "01" } });
      } else {
        this.$router.push({ name: item.title, query: { index: 0 } });
      }
    },
    async init() {
      let res = await getBiddingXmxxByPg({
        current: this.params.current,
        size: this.params.size,
        jjzt: "1",
        xzqbh: this.topSelectAreacode,
      });
      if (res.data.records) {
        this.news = this.resetData(res.data.records);
        console.log(this.news);
      }
    },
    async getTabTwo() {
      let params = {
        ...this.params,
        ...{
          // 这里要改
          xzqbh: this.topSelectAreacode,
        },
      };
      let res1 = await getCjggPage(params);
      let res2 = await getGpggPage(params);
      let res = [];
      if (res1.data.records && res1.data.records.length > 0) {
        res = res.concat(res1.data.records);
      }
      if (res2.data.records && res2.data.records.length > 0) {
        res = res.concat(res2.data.records);
      }
      this.news = this.resetData(res).slice(0, 4);
    },
    resetData(data) {
      let newList = [];
      data.forEach((element, index) => {
        newList.push({
          code: index + 1,
          title: element.xmmc,
          date: element.jjjzsj
            ? element.jjjzsj
            : element.cjgskssj
            ? element.cjgskssj
            : element.ggkssj,
          des: element.lxr,
          from: element.lxr,
          xmbh: element.xmbh,
          sex: element.sex,
          currentIdentity: element.currentIdentity,
        });
      });

      return newList;
    },
    tabHandle(item) {
      this.currentCode = item.code;
      if (item.code == 1) {
        this.init();
      } else if (item.code == 2) {
        this.getTabTwo();
      }
    },

    // 项目详情
    showDetail(row) {
      this.$router.push({ name: "projectDetail", query: { c: row.xmbh } });
    },
  },
};
</script>

<style lang="less" scoped>
ul li {
  list-style: none;
}
.quick-link {
  margin: 0;
  padding: 0;
  display: flex;

  li {
    list-style: none;
    margin: 0 6px;
    padding: 105px 0px 30px 0;

    flex: 1;
    background: #fafafa;
    border: 1px solid #e5e5e5;
    text-align: center;
    font-size: 24px;
    color: #333;
    cursor: pointer;

    &:hover {
      color: #fff;
      transition: 0.3s;
    }

    &.one {
      background: url("../../../assets/icon-r-d-1.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-r-a-1.png") no-repeat center 30px
          #0668b3;
      }
    }

    &.two {
      background: url("../../../assets/icon-r-d-2.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-r-a-2.png") no-repeat center 30px
          #0668b3;
      }
    }

    &.three {
      background: url("../../../assets/icon-r-d-3.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-r-a-3.png") no-repeat center 30px
          #0668b3;
      }
    }
    &.four {
      background: url("../../../assets/icon-r-d-4.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-r-a-4.png") no-repeat center 30px
          #0668b3;
      }
    }
  }
}

.new-area {
  margin-top: 40px;
  display: flex;

  .banner {
    padding-right: 25px;
    width: 460px;
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  .new-list {
    flex: 1;

    .news-tab {
      color: #999;
      font-size: 16px;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      padding: 0px 0 16px 0px;
      li {
        margin: 0px 20px 0px 0px;
        padding-left: 16px;
        font-size: 16px;
        font-weight: bold;

        &:hover {
          cursor: pointer;
          color: #333;
        }

        &.active {
          color: #333;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            top: 10px;
            left: 3px;
            background: #00a0e9;
            border-radius: 5px;
          }
        }
      }
    }

    .new-des {
      li {
        border-bottom: dashed 1px rgba(112, 112, 112, 0.3);
        display: flex;
        padding: 12px 0;

        &:last-child {
          border-bottom: 0;
        }
        .new-date {
          background: #f2f2f2;
          text-align: center;
          color: #666;
          width: 56px;
          height: 52px;
          padding: 0 5px;
          margin-right: 15px;
          font-family: Arial, Helvetica, sans-serif;
          span {
            font-size: 30px;
          }
          p {
            font-size: 12px;
            margin: 0;
            padding: 0;
          }
        }

        .new-title {
          flex: 1;
          > span {
            margin-top: 5px;
            font-size: 16px;
            color: #666;
            font-weight: bold;
          }

          p {
            color: rgba(102, 102, 102, 0.6);
            margin-top: 10px;
            width: 100%;
            display: flex;

            > span {
              flex: 1;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
</style>
