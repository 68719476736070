import service from '../request'
const baseUrl = window.globalUrl.HOME_API;


// 资源资产供应信息
export const getDealNotice= (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/cqjyService/getDealNotice`, 
    params: params
  })
}

// 资源资产公告公示
export const getFundSupervisionNotice= (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/cqjyService/getFundSupervisionNotice`,
    params: params
  })
}

// 生产服务供需信息
export const getSupplyNotice= (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/order/pageList`,
    params: params
  })
}

//分页查询已发布的资源资产供应信息
export const getBiddingXmxxByPg = (params) => {
  return service({
    url: `${window.globalUrl.HOME_API}/trade-website/cqjyService/queryBiddingXmxxByPg`,
    method: 'post',
    params: params,
  })
}

//分页查询资源资产成交公告的信息
export const getCjggPage = (params) => {
  return service({
    url: `${window.globalUrl.HOME_API}/trade-website/cqjyService/queryCjggPage`,
    method: 'get',
    params: params,
  })
}

// 查询当前所有资源资产挂牌公告的信息
export const getGpggPage = (params) => {
  return service({
    url: `${window.globalUrl.HOME_API}/trade-website/cqjyService/queryGpggPage`,
    method: 'get',
    params: params,
  })
}